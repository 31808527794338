import * as Sentry from '@sentry/nextjs'
import { useCallback, useEffect, useRef, useState } from 'react'

export type FetchState<T = unknown> = { type: 'empty' } | { type: 'pending' } | { type: 'failed'; message: string } | { type: 'success'; data: T }
export type UseApiCallback<Req = unknown, Res = unknown> = (data: Req) => Promise<{ data: Res; response: Response }>
export type UseApi<Req = unknown, Res = unknown> = [fetch: UseApiCallback<Req, Res>, state: FetchState<Res>]

export const useApi = <Req = unknown, Res = unknown>(url: string, headers?: HeadersInit, timeout = 30000): UseApi<Req, Res> => {
	const [state, setState] = useState<FetchState<Res>>({ type: 'empty' })
	const abortControllerRef = useRef<AbortController | null>(null)

	useEffect(() => {
		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort()
			}
		}
	}, [])

	const fetchCallback = useCallback(
		async (reqData: Req): Promise<{ data: Res; response: Response }> => {
			setState({ type: 'pending' })
			abortControllerRef.current = new AbortController()

			try {
				const res = await Promise.race([
					fetch(url, {
						method: 'POST',
						body: JSON.stringify(reqData),
						headers: {
							'Content-type': 'application/json',
							...headers,
						},
						signal: abortControllerRef.current.signal,
					}),
					new Promise<never>((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout)),
				])

				if (!res.ok) {
					const errorData = await res.json()
					Sentry.captureException(errorData.error ?? res)
					setState({ type: 'failed', message: errorData.error ?? res.statusText })
					return { response: res, data: { ok: false } as Res }
				}

				if (!res.body) {
					throw new Error('No response body')
				}

				const contentType = res.headers.get('Content-Type')
				if (contentType?.includes('application/json')) {
					const data = (await res.json()) as Res
					setState({ type: 'success', data })
					return { response: res, data }
				}

				const reader = res.body.getReader()
				const decoder = new TextDecoder('utf-8')
				let result = ''
				let partialData: Partial<Res> = {}

				while (true) {
					const { done, value } = await reader.read()
					if (done) break
					result += decoder.decode(value, { stream: true })
					const lines = result.split('\n\n')
					for (const line of lines) {
						if (line.trim()) {
							let message: any
							try {
								message = JSON.parse(line.replace('data: ', ''))
							} catch (e) {
								// If parsing fails, treat it as plain text
								message = { data: line.replace('data: ', '') }
							}

							if (message.ok === false) {
								setState({ type: 'failed', message: message.error })
								return { response: res, data: message as Res }
							}

							partialData = { ...partialData, ...message }
							setState({ type: 'success', data: partialData as Res })
						}
					}
				}

				return { response: res, data: partialData as Res }
			} catch (e) {
				const error = e as Error
				setState({ type: 'failed', message: error.message })
				throw error
			}
		},
		[headers, url, timeout],
	)

	return [fetchCallback, state]
}
